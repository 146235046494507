export default {
  namespaced: true,
  state: {
    status: 'success',
    message: '',
    errorMessage: '',
    delay: 3000,
    error: false,
    errorData: [],
    errorFields: [],
    hideCallback: null
  },
  getters: {
    message: state => state.message,
    errorMessage: state => state.errorMessage,
    delay: state => state.delay,
    hasMessage: state => state.message !== '',
    error: state => state.error,
    errorData: state => state.errorData,
    errorFields: state => state.errorFields
  },
  actions: {
    notifyHide({ commit }) {
      commit('NOTIFY_HIDE')
    },
    notifyShow({ commit }, payload) {
      commit('NOTIFY_HIDE')
      commit('NOTIFY_SHOW', payload)
    },
    notifyErrorShow({ commit }, payload) {
      commit('NOTIFY_HIDE')
      commit('NOTIFY_ERROR_SHOW', payload)
    },
    async notifyErrorHide({ commit, state }) {
      commit('NOTIFY_ERROR_HIDE')
      if (state.hideCallback) {
        await state.hideCallback()
        commit('SET_HIDE_CALLBACK', null)
      }
    },
    NOTIFY_DATA_SHOW({ commit }, responseData) {
      commit('NOTIFY_SHOW_TABLE', responseData)
    }
  },
  mutations: {
    NOTIFY_HIDE(state) {
      state.message = ''
    },
    NOTIFY_SHOW(state, { message, delay }) {
      state.message = message
      state.delay = delay || state.delay
    },
    NOTIFY_ERROR_SHOW(state, { errorMessage }) {
      state.errorMessage = errorMessage
      state.error = true
    },
    NOTIFY_ERROR_HIDE(state) {
      state.error = false
      state.errorMessage = ''
      state.errorData = []
      state.errorFields = []
    },
    NOTIFY_SHOW_TABLE(state, responseData) {
      state.errorData = responseData.data
      state.errorFields = responseData.fields
    },
    SET_HIDE_CALLBACK (state, callback) {
      state.hideCallback = callback
    }
  }
}
